<!--  -->
<template>
  <div class=''>
    <div class="search">
      <span>学分查询：</span>
      <el-input v-model="card" placeholder="身份证号" size="small" style="width: 200px;margin-right: 10px;"></el-input>
      <el-button type="primary" size="mini" icon="el-icon-search" @click="getXuefenList()">搜索</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      class="table"
      style="width: 100%">
      <el-table-column
        prop="name"
        align="center"
        label="姓名">
      </el-table-column>
      <el-table-column
        prop="year"
        sortable
        align="center"
        label="学分年度">
      </el-table-column>
      <el-table-column
        prop="zy_fen"
        align="center"
        label="专业学分">
      </el-table-column>
      <el-table-column
        prop="gx_fen"
        align="center"
        label="公需学分">
      </el-table-column>
    </el-table>
    <div class="page-way" @click="$router.push('Home')">返回首页</div>
  </div>
</template>

<script>
import Api from '@/api/home.js'

export default {
  components: {},
  data() {
    return {
      card: '',
      tableData: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getXuefenList()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getXuefenList () {
      Api.getXuefenList({
        card: this.card
      }).then(res => {
        if (res.data.code == 1) {
          this.tableData = res.data.data
        } else {
          this.$messager.error(res.data.msg)
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
.search {
  display: flex;
  align-items: center;
  font-weight: bold;
  > span {
    font-size: 20px;
    margin-right: 10px;
  }
}
.table {
  margin: 10px 0;
}
.page-way {
  color: #409EFF;
  cursor: pointer;
  text-align: right;
  font-size: 20px;
  margin-right: 30px;
}
</style>